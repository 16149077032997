<script lang="ts" setup>
const config = useContentConfig();

const isDismissed = ref(false);

const route = useRoute();

const isHiddenRoute = computed(
  () =>
    !!config.value?.globalNotificationHiddenPages?.filter(
      (page) => page && getUrl(page) === route.path,
    ).length,
);

if (
  window.localStorage.globalNotificationDismissed
  === config.value?.globalNotificationTitle
) {
  isDismissed.value = true;
}

const dismiss = () => {
  if (config.value?.globalNotificationTitle) {
    window.localStorage.globalNotificationDismissed
      = config.value.globalNotificationTitle;

    isDismissed.value = true;
  }
};
</script>

<template>
  <div
    v-if="config?.globalNotificationEnabled && !isDismissed && !isHiddenRoute"
    class="global-notification"
  >
    <button
      class="absolute right-2 top-2"
      aria-label="Dismiss"
      @click="dismiss"
    >
      <NuxtIcon
        class="lg:text-white"
        name="cross"
        :width="24"
      />
    </button>
    <NuxtImg
      v-if="config.globalNotificationImage?.fields.file"
      :src="config.globalNotificationImage.fields.file.url"
      :alt="config.globalNotificationImage.fields.title"
      class="max-lg:hidden"
      :width="270"
      loading="lazy"
    />
    <div class="flex flex-col items-start gap-3 bg-white p-4">
      <h4 class="max-w-[272px]">
        {{ config.globalNotificationTitle }}
      </h4>
      <NuxtLink
        :to="config.globalNotificationLinkUrl"
        class="btn btn--secondary pl-0"
        @click="dismiss"
      >
        {{ config.globalNotificationLinkText }}
      </NuxtLink>
    </div>
    <hr class="my-0 h-[6px] border-dark-blue-grey-two/10 bg-white" />
    <hr class="my-0 h-[6px] border-dark-blue-grey-two/10 bg-white" />
  </div>
</template>

<style lang="scss" scoped>
.global-notification {
  @apply bottom-0 z-50 border-y-2 border-solid border-dark-blue-grey-two/10 max-lg:sticky;
  @apply lg:fixed lg:bottom-[40px] lg:right-0 lg:w-[272px] lg:translate-x-full lg:overflow-hidden lg:rounded-l lg:border-l-2;

  animation: 300ms ease-out 1s 1 slide-in forwards;

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
}
</style>
